import { useState } from "react";
import { useNavigate } from "react-router";
import headerLogo from "../../assets/Images/sda_header_logo-removebg-preview.png";
import { login } from "../../constants/api";

interface LoginPageProps {
  onLoginSuccess: () => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevent default form submission
    try {
      const response = await login(username, password);
      const { access, refresh } = response.data;
      localStorage.setItem("access_token", access);
      localStorage.setItem("refresh_token", refresh);
      onLoginSuccess();
    } catch (error) {
      console.error("Login failed", error);
      setError("Invalid username or password");
      window.location.reload();
    }
  };

  return (
    <div className="flex flex-col lg:flex-row justify-between bg-red-900 min-h-screen">
      <div className="flex flex-col items-center lg:items-start p-6 lg:p-0 lg:ml-14">
        <img
          src={headerLogo}
          alt="Header Logo"
          className="h-20 w-52 mt-8 lg:mt-14 bg-white cursor-pointer"
          onClick={() => navigate("/")}
        />
        <div className="mt-12 lg:ml-20 lg:mt-36 text-center lg:text-left">
          <h1 className="text-amber-100 font-eb-garamond text-3xl lg:text-4xl italic">
            Log in
          </h1>
          <h1 className="text-amber-100 font-anton font-bold text-6xl lg:text-9xl">
            Welcome
          </h1>
          <h1 className="text-amber-100 font-anton font-bold text-6xl lg:text-9xl">
            Back, Sigma
          </h1>
          <p className="text-amber-100 font-antonio font-bold text-lg mt-4 lg:mt-0">
            Sign back into your account to manage content.
          </p>
        </div>
      </div>
      <div className="bg-amber-100 mt-8 lg:mt-32 px-8 py-12 lg:px-24 lg:py-16 lg:mr-12 lg:mb-32 flex flex-col items-center lg:items-start">
        <h1 className="font-anton font-bold text-3xl lg:text-4xl text-sky-900">
          Your Account
        </h1>
        <form className="flex flex-col mt-8 w-full" onSubmit={handleLogin}>
          <div>
            <label className="block mb-2 font-bold font-anton text-sky-900 text-sm lg:text-base">
              Username
            </label>
            <input
              type="text"
              id="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="bg-transparent border border-black text-sm block w-full p-2.5"
              placeholder="Enter your username"
              required
            />
          </div>
          <div className="mt-4">
            <label className="block mb-2 font-bold font-anton text-sky-900 text-sm lg:text-base">
              Password
            </label>
            <input
              type="password"
              id="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="bg-transparent border border-black text-sm block w-full lg:w-96 p-2.5"
              placeholder="Enter your password"
              required
            />
          </div>
          {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
          <button
            type="submit"
            className="w-full lg:w-20 h-10 bg-emerald-600 mt-8 font-anton font-bold text-sm"
          >
            LOG IN
          </button>
        </form>
        <div className="mt-16 lg:mt-64 w-full">
          <div className="border-gray-400 border-y mt-8 mb-4"></div>
          <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start">
            <h1 className="font-eb-garamond font-thin text-center lg:text-left">
              Not allowed to be here?
            </h1>
            <div
              className="flex flex-col cursor-pointer mt-4 lg:mt-0"
              onClick={() => navigate(`/`)}
            >
              <div className="flex flex-row justify-center lg:justify-start">
                <h1 className="font-eb-garamond font-thin">Return Home</h1>
              </div>
              <div className="border-gray-300 border-y w-full"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
